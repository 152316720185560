.document {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
}
.page {
  position: relative;
  overflow: auto;
  scrollbar-width: none;
}
