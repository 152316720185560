body {
  margin: 0;
  font-family:'Inter-Regular', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter-Regular', 'Open Sans', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track{
  background-color: #e4e4e7;
}

::-webkit-scrollbar-thumb{
  background-color: #3c4b64;
  border-radius: 6px;
}